import React, { useEffect, Fragment } from "react";
import CTA from "../general/cta";
import EntryIllustration from "../../../../assets/img/cowry-hero-image.inline.svg";
import { scrollToElement } from "../../../utility/utils";
import BelowFoldCards from "../general/belowFoldCards";
import BadgesEntryFold from "../general/badgesEntryFold";
import WithdrawIcon from "../../../../assets/img/withdrawIcon.inline.svg"
import NairaIcon from "../../../../assets/img/naira.inline.svg"
import Zero from "../../../../assets/img/zero-card-icon.inline.svg"
import FirstSectionImage from "../../../../assets/img/choose-a-package.inline.svg"
import SecondSectionImage from "../../../../assets/img/kuda-cardless-illustration.inline.svg"
import ImageTextRight from "../general/imageTextRight";
import ImageTextLeft from "../general/imageTextLeft";
import FAQList from "../general/faqsList";
import MoreForYouComponent from "../general/more-for-you";

import TransferIcon from '../../../../assets/img/more-for-you/kuda-transfer.inline.svg'
import KudaAirtimeIcon from '../../../../assets/img/more-for-you/kuda-airtime-icon.inline.svg'
import BorrowIcon from '../../../../assets/img/more-for-you/kuda-borrow-icon.inline.svg'

const entryContent = {
    title: "Top up your Cowry wallet easily on the Kuda app!",
    subtitle: "Pay for bus, train and ferry rides in Lagos comfortably with your topped-up Cowry wallet.",
    illustration: <EntryIllustration />
}
const kudaTopFeatures = [
    {
        icon: <NairaIcon />,
        text: "Top up your Cowry wallet easily.",
    },
    {
        icon: <WithdrawIcon />,
        text: "Make cashless transport payments across Lagos.",
    },
    {
        icon: <Zero />,
        text: "Never worry about having change for bus rides again.",
    },
]
const firstSection = {
    title: "Simplify your Lagos transport payments.",
    subtitle: "Top up your Cowry wallet on the Kuda app and tap your wallet or Cowry card to pay across the city.",
    url: "https://help.kuda.com/support/solutions/articles/73000610757-top-up-your-cowry-wallet/",
    name: "Learn How To Top Up A Cowry Wallet.",
    illustration: <FirstSectionImage />,
}
const secondSection = {
    title: "Go cashless, even on danfo bus rides!",
    subtitle: "With your Cowry wallet topped up on the Kuda app, you’ll never worry about having change to pay bus conductors again.",
    url: "https://kuda.onelink.me/abUI/344e3dde",
    name: "Download Kuda",
    illustration: <SecondSectionImage />,
}

const questions = [
    {
        heading: "What is Cowry and how does it work?",
        list: (
            <span className="flex flex-column inApp-contact-ways--wrap">
                <span className="faq-description f-16 mb-3">
                    Cowry is a digital wallet for making transport payments in Lagos. </span>
                <span className="faq-description f-16">
                    After downloading the Cowry app on your app store, signing up and adding money to the wallet,
                    you can pay for trips on public buses, trains and ferries across Lagos by tapping your phone
                    or the optional Cowry card on payment terminals.
                </span>

            </span>),
        index: 1
    },
    {
        heading: "How can I use Cowry?",
        list: (
            <span className="flex flex-column inApp-contact-ways--wrap">
                <span className="faq-description f-16">
                    You just need to download the Cowry app on your app store and sign up,
                    then you can add money to your Cowry wallet on the Kuda app.
                </span>

            </span>),
        index: 2
    },
    {
        heading: "How do I top up my Cowry wallet on the Kuda app?",
        list: (
            <span className="faq-description f-16 flex flex-column">
                <span className="faq-description f-16"> To top up your Cowry wallet on the Kuda app, follow the steps below: </span>
                <span className="pt-3 career-message-margin-bottom ">
                    <div className="flex flex-column  leading-md-6 f-md-16">
                        <div className="mb-3">1. Sign in to your Kuda app, then tap <b>Pay</b>.</div>
                        <div className="mb-3">2. Tap <b>Pay A Bill</b>.</div>
                        <div className="mb-3"> 3. Tap <b>Transport & Toll</b>.</div>
                        <div className="mb-3">4. Tap the service provider dropdown menu.</div>
                        <div className="mb-3">5. Tap <b>LASG Cowry Card</b>.</div>
                        <div className="mb-3">6. Tap the top-up package dropdown menu.</div>
                        <div className="mb-3">7. Choose a top-up package.</div>
                        <div className="mb-3">8. Type in the phone number linked to your Cowry wallet, then tap <b>Next</b>.</div>
                        <div className="mb-3">9. Complete the top-up with your transaction PIN, fingerprint or Face ID.</div>
                        <div className="mb-3">10. Tap <b>Okay</b> to return to your Kuda dashboard.</div>
                    </div>
                </span>

            </span>

        ),
        index: 3
    },
    {
        heading: "What transport fare can I pay with Cowry?",
        list: (
            <span className="pt-3 career-message-margin-bottom ">
                <ul className="faq-description f-16 diclaimer-list">
                    <li>BRT bus fares</li>
                    <li>Danfo bus fares</li>
                    <li>LAGFERRY fares</li>
                    <li>Blue Line and Red Line train fare.</li>
                </ul>

            </span>

        ),
        index: 4
    },
    {
        heading: "Can I check my Cowry transaction history on the Kuda app?",
        list: (
            <span className="flex flex-column inApp-contact-ways--wrap">
                <span className="faq-description f-16">No, you can’t. You’ll need to sign in to your Cowry app to check your Cowry transaction history.</span>

            </span>),
        index: 5
    },
    {
        heading: "Can I make Cowry payments with the Kuda app?",
        list: (
            <span className="flex flex-column inApp-contact-ways--wrap">
                <span className="faq-description f-16">No, you can’t. You need the Cowry app or the optional Cowry card to make Cowry payments.</span>

            </span>),
        index: 6
    },
]

const moreForYou = [
    {
        icon: <TransferIcon />,
        title: "Transfer & Spend",
        subText: "Send money for free to any Nigerian account with 25 free transfers every month.",
        linkTo: `/en-ng/spend/`
      },
      {
        icon: <KudaAirtimeIcon />,
        title: "Airtime",
        subText: `Buy Airtel, Glo, MTN or 9Mobile airtime directly from your account on your Kuda app.`,
        linkTo: `/en-ng/airtime/`
      },
    {
      icon: <BorrowIcon />,
      title: "Loans",
      subText: `Get instant loans up to ₦150,000 in the Kuda loan app easily in Nigeria without paperwork.`,
      linkTo: `/en-ng/personal-loan/`
    },
  
  ]
const Cowry = () => {

    useEffect(() => {
        scrollToElement();
        window.addEventListener("scroll", function () {
            scrollToElement();
        });
    }, [])

    return (
        <Fragment>
            <BadgesEntryFold
                title={entryContent.title}
                subtitle={entryContent.subtitle}
                illustration={entryContent.illustration}
            />
            <BelowFoldCards topFeatures={kudaTopFeatures} />
            <ImageTextRight
                title={firstSection.title}
                subtitle={firstSection.subtitle}
                illustration={firstSection.illustration}
                name={firstSection.name}
                url={firstSection.url}
            />
            <ImageTextLeft
                title={secondSection.title}
                subtitle={secondSection.subtitle}
                illustration={secondSection.illustration}
                name={secondSection.name}
                url={secondSection.url}
            />
            <FAQList title={"Cowry FAQs"} questions={questions} />
            <MoreForYouComponent moreForYou={moreForYou} />
            <CTA />
        </Fragment>
    )
}

export default Cowry;
